
@import "./main.css"; 



.aButton{
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
@media only screen and (max-width: 700px) {
    .aButton{
        display: flex;
        justify-content: center;
        text-decoration: none;
    }
}

.aButton:link{
    text-decoration: none;
}
.aButton:hover{
    text-decoration: none;
}

.playButton{
    height: 3rem;
    width: auto;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 2px 2px 1px #ccc;   /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 2px 2px 1px #ccc;    /* Firefox 3.5 - 3.6 */
     box-shadow:        3px 2px 2px 1px #ccc;   /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.wmButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    min-width: 200px;
    margin-top: 1rem;
    height: 3rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 2px 2px 1px #ccc;   /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 2px 2px 1px #ccc;    /* Firefox 3.5 - 3.6 */
     box-shadow:        3px 2px 2px 1px #ccc;   /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

    background-color: #7c6cf3;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1.0rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
}
.wmButton:hover{
    background-color: #8677f1;
}

.wappButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    min-width: 200px;
    margin-top: 1rem;
    height: 3rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 3px 2px 2px 1px #ccc;   /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 2px 2px 1px #ccc;    /* Firefox 3.5 - 3.6 */
     box-shadow:        3px 2px 2px 1px #ccc;   /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

    background-color: #25d366;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1.0rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
}
.wappButton:hover{
    background-color: #41ce75;
}

.textButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    min-width: 200px;
    margin-top: 1rem;
    height: 3rem;
    font-family: 'Roboto', sans-serif;
    color: var(--color-wm-pr);
    font-size: 1.0rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
}