@import "./main.css"; 


.feature{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.featureIcon{
    display: flex;
    color: var(--color-wm-p);
}

.featureText{   
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 70%;
    margin-left: 1rem;
}

.featureIconSize{
    font-size: 3.5rem !important;
}


.featureText h3{
    line-height:1.5rem; 
    letter-spacing: 0.02rem;
    font-size: 1.4rem;
    margin-bottom: 0.3rem;
    color: var(--color-body);
   
}
.featureText p{
    font-size: 1.2rem;
    color: var(--color-disabled);
}

@media only screen and (max-width: 700px) {
    .feature{
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin-bottom: 1.5rem;
        justify-content: space-between;
    }

    .featureIconSize{
        width: 2.5rem;
        font-size: 2.5rem !important;
    }

    .featureText{   
        width: 81%;
    }


    .featureText h3{
        letter-spacing: 0.02rem;
        font-size: 1.1rem;
    }

    .featureText p{
        font-size: 1.0rem;
        color: var(--color-disabled);
    }
}


