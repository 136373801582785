@import "./main.css";




.container-landscape {
    width: 90%;

    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 10vh;
    min-height: 70vh;
}

.section-small {
    width: 40%;
}

.section-large {
    width: 50%;

}

.youtube {
    margin: 1rem;
    height: auto;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 2rem;
    border: none;
}

.youtube iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
}

@media only screen and (max-width: 1200px) {

    .youtube {

        margin-top: 3rem;
        border-radius: 1rem;
    }

    .container-landscape {
        flex-direction: column;
        padding-bottom: 10%;
        width: 100%;
    }

    .section-small {
        width: 100%;
        margin: 0;
    }

    .section-large {
        width: 100%;
        margin: 0;
    }
}

/* --------------  container row ----------*/

.container-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 70vh;

    padding: 3%;
}

.container-section {
    display: flex;
    width: 90%;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.container-section-reverse {
    display: flex;
    width: 90%;
    justify-content: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
}


.image-v {
    width: auto;
    max-width: 90%;
    max-height: 90vh;
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 3%;
    margin-left: 3%;
}

.image-h {
    max-width: 120%;
    max-height: 90vh;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 1200px) {
    .image-h {
        max-width: 100%;
        max-height: 95vh;
        margin-right: 0;
        margin-left: 0;
    }

    .image-v {

        height: auto;
        align-self: center;
        max-width: 100%;
        max-height: 95vh;
        margin-right: 0;
        margin-left: 0;
    }

    .container-section {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .container-section-reverse {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

}



/*   Section  
.section-row{
    display: flex;
    width: 100%; 
}----------*/

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
}

.section h1 {

    font-size: 4.0rem;
    line-height: 5rem;
    overflow-wrap: break-word;
}

.section h2 {

    font-size: 0.8rem;
    padding-top: 0.5rem;
    text-transform: uppercase;
    color: var(--color-disabled);
}

.section-p {
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-wrap: break-word;
}

.section a {
    text-align: center;
}


.box-card-shadow {
    background-color: #1c1c1c12;
    border-radius: 2rem;
    border: none;
}

.box-card-primary {
    background-color: var(--color-wm-p);
    border-radius: 2rem;
    border: none;
}


@media only screen and (max-width: 1200px) {

    .section {
        width: 95%;
        padding: 0;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .section h1 {
        font-size: 2.8rem;
        line-height: 3.2rem;
        text-align: center;
    }

    .section h2 {
        font-size: 0.8rem;
        padding-top: 0.5rem;
    }

    .section-p {
        font-size: 1.3rem;
        padding-top: 1rem;
    }


}




/*          Social   section     */
.social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.social i {
    margin: 0.5rem;
    font-size: 2.0rem;
    color: gray;
}

.social a {
    color: gray;
}

.social i:hover {
    color: #8b7df3;
}

.social h3 {
    color: gray;
    font-size: 1rem;
    text-transform: uppercase;
}

.social p {
    color: gray;
    font-size: 1.5rem;
}


.separator {
    color: gray;
    font-size: 2rem;
    padding: 2rem;
}

@media only screen and (max-width: 700px) {
    .separator {
        display: none;
    }
}

/*    footer  */

.footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    padding: 1rem;
}


.footer p {
    line-height: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;

    font-size: 1rem;
    color: gray;
    min-width: 300px;
    text-align: center;
}





/* Animations  */
.fade-in {
    animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



.grid {
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 1.5rem;


}

.grid-cell {
    /*background-color: red;*/
    width: 50%;
}

@media only screen and (max-width: 700px) {
    .grid-cell {

        padding: 0rem;
        width: 100%;
    }
}



.list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 1.5rem;
}

.li {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-top: 1.5rem;
}

.li-icon {
    display: flex;
    padding-right: 0.8rem;
    padding-bottom: 1rem;
    color: var(--color-wm-p);
}

.li-size-icon {
    font-size: 3rem !important;
}


.li-text {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 70%;
}

.li-text h1 {
    line-height: 1.3rem;
    letter-spacing: 0.05rem;
    font-size: 1.2rem;
    font-family: 'Anton', cursive;
    font-style: italic;

    text-transform: uppercase;
}

.li-text p {
    font-size: 1.2rem;
    color: gray;
}