@import "./main.css"; 

.carousel {
  width: 70%;
  overflow: hidden;
  margin: auto;
  position: relative;
  padding-bottom: 1rem;
}


@media only screen and (max-width: 1200px) {
  .carousel{
    width: 100%;
    max-width: 500px;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}

.carouselInner {
  display: flex;
  transition: transform 0.5s ease-in-out;
 
}

.carouselItem {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 1.2rem;
}

.carouselItem img {
  width: 100%;
  display: flex;
}

.carouselItemTitle {
  width: 80%;
  position: absolute;
  bottom: 3rem;
  height: 3rem;
  left: 10%;
  transition: background-color 0.1s ease;
  background: rgba(0, 0, 0, 0.8);
  color: white;
 
 
  border-radius: 1.0rem;
  font-size: 1.5rem;
  text-align: center;
  padding-top: 0.5rem;
}

.indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0,0, 0.3);
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: rgba(0, 0, 0, 1);
}
