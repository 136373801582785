
@import "./main.css"; 


.header{
    display: flex;
    justify-content: space-between;
    width: 100%; 
  
}


.brand {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
}
.brandImage{
   
    width:  2.5rem;
    padding: 0.5rem;
}

.brandTitle{
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    
    /* font-family: 'Baloo 2', cursive; font-family: 'Adumu', cursive; */
    font-weight: bold;
    color: var(--color-title);
}
.brandTitleMenu{
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    color: var(--color-title);
}


.menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10%;
}

.menuItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.1rem;
}


.menuItemName  {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    margin: 0.5rem;
 
    padding-top:0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
 }
 
 .menuItemName:hover{
     background-color: var(--color-gray300);
     border-radius: 0.5rem;
 }
 
 .menuItemName a {
     color: black;
     text-decoration: none;
 }


.submenuDropdownIcon{
    margin-left: 0.2rem;
}

.submenuDropdown{
    position: absolute;
    top: 50px;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
    font-size: 0.9rem;
}

.submenuDropdown a {
    color: black;
    text-decoration: none;
    padding-top:0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin: 0.5rem;
    font-size: 1rem;
}

.submenuDropdown a:hover{
    background-color: var(--color-gray300);
    border-radius: 0.5rem;
}

.submenu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
}
.submenu a {
    color: black;
    text-decoration: none;
    padding-top:0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin: 0.5rem;
    font-size: 1rem;
}


.menuMobile{
    display: none;
}
.menuMobileIcon{
    display: none;
}
.displayButtonMenuMobile{
    display: none;
}

@media (max-width: 700px) {
.menu{
        display: none;
    }

 
    .displayButtonMenuMobile{
        display:flex;
    }

    .menuMobileIcon{
        display: flex;
        align-items: center;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        margin-left: 1rem;
        color:  white;
        background-color: #7B6CF3;
        border-radius: 0.5rem;
    }
    

    .menuMobile{
        display: flex;
        position:absolute;
        flex-direction: column;
        right:0;
        top: 60px;
        width: 0%;
        height: calc(100vh - 60px);
        transition: 0.7s ease-in-out;
        background-color: white;
        overflow: hidden;
    }

    .menuMobile.active {    
        width: 100%;
    }

    .menuMobileContent{ 
        display: flex;
        padding-top: 0.1rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;   
        margin: 2rem;
        transition: all 0.3s ease-in;
      }
   
  }