.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ic-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .ic-logo {
    animation: logo-spin 1.5s ease-in-out infinite alternate;
  }
}

@keyframes logo-spin {
  from {
    transform: scale(1.0);
  }
  to {
    transform: scale(1.2);
  }
}
