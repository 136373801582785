*{
    font-family: 'Poppins', sans-serif;
    margin: 0; 
    padding: 0;
    --color-wm-p: #7B6CF3;
    --color-gray300: #E0E0E0;
    --color-disabledLigth: #EEEEEE;   /* 300 */
    --color-disabled: #9E9E9E;            /* 500 */
    --color-body: #616161;            /* 700 */
    --color-subtitle: #424242;   /* 800 */
    --color-title:#212121;       /* 900 */ 
}

h1 {
    font-family: 'Poppins',sans-serif;
    color: var(--color-title);
}

h2,h3 {
    font-family: 'Poppins',sans-serif;
    color: var(--color-subtitle);
}


p {
    color: var(--color-body);  
}

.color-on-primary{
    color: var(--color-subtitle);
}

.color-light{
    background-color: #f8f9fa;
}

.color-dark{
    background-color: #f8f9fa;
}
.color-footer{
    background-color: #0d1824;
}

.color-primary{
    background-color: aqua;
}




.body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

main{
    padding-top: 0%;
    padding-bottom: 2%;
}